.hero {
  position: relative;
  margin-bottom: 70px;
  height: 100vh;
  background-color: #fff;

  @include respond-to(md) {
    margin-bottom: 200px;
  }

  &__media {
    position: relative;
    height: 100%;

    img,
    video {
      @include objectFit;
    }

    .embed-container {
      position: relative;
      padding: 0;
      height: 100vh;
      overflow: hidden;
    }

    iframe {
      box-sizing: border-box;
      height: 56.25vw;
      left: 50%;
      min-height: 100%;
      min-width: 100%;
      transform: translate(-50%, -50%);
      position: absolute;
      top: 50%;
      width: 177.77777778vh;
    }

    &::after {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: -2px;
      background: linear-gradient(
        to right,
        rgba(18, 18, 18, 0.87) 6.25%,
        rgba(18, 18, 18, 0) 66.67%
      );
      content: '';
    }

    @include when-inside('.hero--home') {
      &::after {
        background-image: linear-gradient(
            to top,
            rgba(18, 18, 18, 0.65) 8.85%,
            rgba(18, 18, 18, 0) 94.44%
          ),
          linear-gradient(to top, #000000 -28.61%, rgba(0, 0, 0, 0) 42.82%);
      }
    }
  }

  &__intro {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 15;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    font-size: 1.5rem;
    text-align: center;
    font-weight: 900;
    transform: translateX(-50%);
    pointer-events: none;

    span {
      span {
        color: $ldnm-color;
      }
    }

    @include respond-to(sm) {
      font-size: 2rem;
    }

    @include respond-to(lg) {
      font-size: 2.4rem;
    }

    @include respond-to(xl) {
      font-size: 3.4rem;
    }

    @include respond-to(xxl) {
      font-size: 4.4rem;
    }
  }

  &__title {
    font-size: 4rem;
    line-height: 1.1;

    span {
      color: $brand-color;
    }

    @include respond-to(sm) {
      font-size: 8rem;
      opacity: 0;
    }

    @include respond-to(lg) {
      font-size: 10rem;
    }

    @include respond-to(xl) {
      font-size: 12rem;
    }

    @include respond-to(xxl) {
      font-size: 16.6rem;
    }
  }

  &__scroll {
    position: absolute;
    z-index: 2;
    left: 50%;
    bottom: 50px;
    font-weight: 900;
    text-transform: uppercase;
    transform: translateX(-50%);
    @include transition;

    @include hover {
      transform: translate(-50%, -4px);
    }

    @include respond-to(lg) {
      bottom: 100px;
    }
  }

  &__card {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 3;
    padding: 50px 17px;
    width: 260px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    transform: translate(-50%, -50%);
    background-color: rgba($black-color, 0.6);

    h1 {
      margin-bottom: 15px;
    }

    h3 {
      margin-bottom: 30px;
    }

    @include respond-to(sm) {
      padding: 50px 70px;
      width: 650px;

      h1 {
        margin-bottom: 25px;
      }

      h3 {
        margin-bottom: 50px;
      }
    }

    @include respond-to(lg) {
      padding-top: 80px;
      padding-bottom: 80px;
      width: 720px;
    }

    @include respond-to(xxl) {
      padding-top: 127px;
      padding-bottom: 127px;
      width: 820px;
    }
  }

  &__copy {
    position: absolute;
    left: var(--container-padding);
    bottom: 20vh;
    width: 80%;
    max-width: 300px;

    h1 {
      margin-bottom: 15px;
    }

    span {
      margin-bottom: 10px;
      display: inline-block;
      color: $brand-color;
      font-weight: 900;
      text-transform: uppercase;
    }

    @include respond-to(sm) {
      max-width: 400px;

      span {
        margin-bottom: 18px;
      }

      h1 {
        margin-bottom: 30px;
      }
    }

    @include respond-to(md) {
      span {
        font-size: 1.6rem;
      }
    }
  }
}

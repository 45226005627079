.hero-text {
  margin-bottom: 70px;
  text-align: center;

  &--50 {
    margin-bottom: 50px;
  }

  @include respond-to(sm) {
    margin-bottom: 125px;
  }

  @include respond-to(md) {
    margin-bottom: 200px;
  }

  &__inner {
    margin-left: auto;
    margin-right: auto;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      margin-top: 30px;
      + p {
        margin-top: 20px;
      }

      a {
        color: $brand-color;
        @include underline;
      }
    }

    .cta,
    .cta-secondary {
      display: inline-block;
    }

    @include respond-to(sm) {
      width: 80%;
      max-width: 700px;

      p {
        margin-top: 50px;
      }
    }

    @include respond-to(lg) {
      max-width: 740px;

      p {
        font-size: 1.8rem;
      }
    }

    @include respond-to(xxl) {
      max-width: 940px;

      p {
        font-size: 2.4rem;
      }
    }
  }

  &__btn-wrap {
    margin-top: 30px;
    display: flex;
    align-items: center;

    > span {
      margin-left: 15px;
      margin-right: 15px;
    }

    img {
      padding-bottom: 10px;
      width: 100px;
    }

    @include respond-to(sm) {
      margin-top: 50px;

      img {
        padding-bottom: 15px;
        width: 150px;
      }

      > span {
        margin-left: 25px;
        margin-right: 25px;
      }
    }
  }
}

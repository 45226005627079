// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------
.footer-main {
  background-color: $black-color;

  a {
    color: inherit;
  }

  &__gymbox {
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 1px solid rgba($brand-color, 0.4);

    a {
      display: inline-flex;
      align-items: center;
    }

    @include respond-to(sm) {
      padding-bottom: 30px;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
    }
  }

  &__logo {
    width: 65px;

    img,
    svg {
      width: 100%;
      fill: $white-color;
      @include transition;
    }

    @include hover {
      svg {
        fill: $brand-color;
      }
    }

    @include respond-to(sm) {
      width: 91px;
    }
  }

  &__link {
    margin-top: 15px;
    font-weight: 900;
    font-size: 1.2rem;

    img {
      margin-left: 5px;
      margin-bottom: 3px;
      @include transition;
    }

    @include hover {
      img {
        transform: translateX(3px);
      }
    }

    @include respond-to(sm) {
      font-size: 1.8rem;

      img {
        margin-bottom: 5px;
        margin-left: 10px;
        width: 17px;
        height: 12px;
      }
    }
  }

  &__inner {
    padding-top: 30px;
    padding-bottom: 30px;

    @include respond-to(sm) {
      padding-top: 50px;
      padding-bottom: 70px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }
  }

  &__menu {
    margin-bottom: 30px;
    text-align: center;
    font-size: 1.4rem;

    li {
      &:not(:last-child) {
        margin-bottom: 30px;
      }
    }

    a {
      text-transform: uppercase;

      @include hover {
        color: $brand-color;
      }
    }

    @include respond-to(sm) {
      margin-bottom: 0;
      display: flex;

      li {
        &:not(:last-child) {
          margin-right: 40px;
          margin-bottom: 0;
        }
      }
    }

    @include respond-to(md) {
      font-size: 1.5rem;
    }

    @include respond-to(lg) {
      li {
        &:not(:last-child) {
          margin-right: 80px;
        }
      }
    }

    @include respond-to(xxl) {
      font-size: 1.8rem;
    }
  }

  &__social {
    margin-left: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    li {
      &:not(:last-child) {
        margin-right: 22px;
      }
    }

    a {
      display: flex;
      justify-content: center;
      align-items: center;

      @include hover {
        color: $brand-color;
      }
    }

    svg {
      width: 35px;
      height: 35px;
    }

    path {
      fill: currentColor;
    }

    @include respond-to(md) {
      li {
        &:not(:last-child) {
          margin-right: 30px;
        }
      }
    }

    @include respond-to(xxl) {
      svg {
        width: 48px;
        height: 48px;
      }
    }
  }
}

.footer-secondary {
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: $brand-color;

  &__inner {
    padding-top: 30px;
    padding-bottom: 30px;
    font-size: 1.2rem;
    text-align: center;
    color: $black-color;

    @include respond-to(sm) {
      position: relative;
      padding-top: 50px;
      padding-bottom: 50px;
      display: flex;
      align-items: flex-end;
      font-size: 1.4rem;
    }

    @include respond-to(md) {
      font-size: 1.5rem;
    }

    @include respond-to(xl) {
      font-size: 1.8rem;
    }
  }

  &__ldnm {
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 1.1;

    @media (max-width: 767px) {
      font-size: 1rem;
    }

    a {
      margin-top: 5px;
      margin-right: -7px;
      width: 116px;
      display: block;

      @include hover {
        opacity: 0.7;
      }
    }

    @include respond-to(sm) {
      margin-bottom: 0;
      align-items: flex-start;
      text-align: left;
      font-size: 1.4rem;

      a {
        width: 150px;
      }

      img {
        width: 100%;
      }
    }
  }

  &__copyright {
    margin-bottom: 30px;

    p {
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    a {
      color: inherit;
      @include underline;
    }

    @include respond-to(sm) {
      position: absolute;
      bottom: 50px;
      left: 50%;
      margin-bottom: 0;
      height: calc(100% - 100px);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      transform: translateX(-50%);
    }

    @include respond-to(lg) {
      br {
        display: none;
      }
    }
  }

  &__48-1 {
    color: $black-color;
    text-transform: uppercase;
    @include underline;

    @include respond-to(sm) {
      margin-left: auto;
    }
  }

  &__logos {
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $black-color;

    img {
      &:not(:last-child) {
        margin-right: 19px;
      }
    }

    @include respond-to(sm) {
      padding-bottom: 26px;
    }
  }

  &__visa {
    width: 30px;
    height: 10px;

    @include respond-to(sm) {
      width: 48px;
      height: 15px;
    }
  }

  &__mastercard {
    width: 21px;
    height: 13px;

    @include respond-to(sm) {
      width: 33px;
      height: 21px;
    }
  }

  &__sage {
    width: 34px;
    height: 13px;

    @include respond-to(sm) {
      width: 54px;
      height: 21px;
    }
  }

  &__paypal {
    width: 41px;
    height: 11px;

    @include respond-to(sm) {
      width: 66px;
      height: 18px;
    }
  }
}

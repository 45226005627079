.title-copy {
  margin-bottom: 70px;

  @include respond-to(sm) {
    margin-bottom: 125px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  @include respond-to(md) {
    margin-bottom: 200px;
  }

  &__title {
    margin-bottom: 25px;

    @include respond-to(sm) {
      margin-bottom: 0;
      width: 45%;
    }
  }

  &__copy {
    p {
      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }
    @include respond-to(sm) {
      width: 50%;

      p {
        &:not(:last-child) {
          margin-bottom: 25px;
        }
      }
    }
  }
}

.list {
  margin-bottom: 70px;

  @include respond-to(sm) {
    ul {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }

  @include respond-to(md) {
    margin-bottom: 200px;
  }

  &__item {
    margin-top: 30px;
    @include transition-sequence(0s, '0.2s');

    &:first-child {
      margin-top: 0;
    }

    strong {
      margin-bottom: 15px;
      padding-bottom: 10px;
      display: block;
      border-bottom: 1px solid $brand-color;

      @include respond-to(sm) {
        display: inline-flex;
        align-items: flex-end;
        width: 100%;
        min-height: 54px;
      }

      @include respond-to(lg) {
        min-height: 59px;
      }

      @include respond-to(xl) {
        min-height: 67px;
      }
    }

    @include respond-to(sm) {
      margin-top: 50px;
      width: calc(50% - 20px);

      &:nth-child(2) {
        margin-top: 0;
      }
    }
  }
}

.date-btn {
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  text-align: left;

  @include respond-to(sm) {
    padding-top: 50px;
    padding-bottom: 50px;
    display: grid;
    grid-template-columns: 1fr 1fr 100px;
    row-gap: 5px;
  }

  @include respond-to(md) {
    padding-top: 70px;
    padding-bottom: 70px;
    grid-template-columns: 1fr 1fr 170px;
  }

  &__title {
    margin-bottom: 5px;
    width: calc(100% - 30px);

    @include respond-to(sm) {
      margin-bottom: 0;
      width: 100%;
    }
  }

  &__enroll {
    position: relative;
    text-align: right;
    color: $brand-color;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: $brand-color;

    span {
      position: absolute;
      top: 0;
      right: 0;
      color: $brand-color;
      -webkit-text-fill-color: $black-color;
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: $brand-color;
      clip-path: circle(0px at 100% 30%);
      @include transition-slow;
    }

    @include hover {
      span {
        clip-path: circle(200% at 100% 30%);
      }
    }

    @media (max-width: 767px) {
      display: none;
    }
  }

  &__arrow {
    margin-left: auto;
    @include transition;

    img {
      width: 18px;
    }

    @include respond-to(sm) {
      img {
        width: 38px;
      }
    }

    @include when-inside('.is-opened') {
      transform: rotate(180deg);
    }
  }

  &__start,
  &__count {
    font-size: 1rem;

    @include respond-to(sm) {
      font-size: 1.8rem;
    }

    @include respond-to(md) {
      font-size: 2.4rem;
    }
  }

  &__count {
    &::before {
      content: ' - ';
    }

    @include respond-to(sm) {
      text-align: right;
      &::before {
        display: none;
      }
    }
  }
}

// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------
@font-face {
  font-family: 'Helvetica';
  src: url('../fonts/helvetica-heavy.woff2') format('woff2'),
    url('../fonts/helvetica-heavy.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica';
  src: url('../fonts/helvetica.woff2') format('woff2'),
    url('../fonts/helvetica.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

// -----------------------------------------------------------------------------
// This file contains all styles related to the button component.
// -----------------------------------------------------------------------------
.cta {
  position: relative;
  padding-top: 12px;
  padding-bottom: 8px;
  padding-left: 14px;
  padding-right: 14px;
  display: inline-block;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 800;
  white-space: nowrap;
  color: $black-color;
  background-color: $brand-color;
  border: 1px solid $brand-color;
  overflow: hidden;

  @include respond-to(sm) {
    padding-top: 15px;
    padding-bottom: 9px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 1.6rem;
  }

  &::before {
    position: absolute;
    right: 10px;
    bottom: 10px;
    border-radius: 50%;
    background-color: $black-color;
    content: '';
  }

  span {
    white-space: nowrap;
  }

  &__inner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding-top: 12px;
    padding-bottom: 8px;
    padding-left: 14px;
    padding-right: 14px;
    color: $brand-color;
    background-color: $black-color;
    clip-path: circle(0px at 95% 85%);
    @include transition;

    @include respond-to(sm) {
      padding-top: 15px;
      padding-bottom: 9px;
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  @include hover {
    .cta__inner {
      clip-path: circle(150% at 95% 85%);
    }
  }
}

.cta-secondary {
  position: relative;
  font-size: 1.2rem;
  color: $brand-color;
  font-weight: 900;
  text-transform: uppercase;

  svg {
    position: absolute;
    left: calc(100% + 5px);
    // margin-left: 5px;
    margin-bottom: 2px;
    @include transition;
  }

  @include hover {
    svg {
      transform: translateX(5px);
    }
  }

  path {
    fill: currentColor;
  }

  @include respond-to(sm) {
    font-size: 1.8rem;

    svg {
      margin-left: 10px;
      margin-bottom: 0;
      width: 19px;
      height: 15px;
    }

    @include hover {
      svg {
        transform: translateX(10px);
      }
    }
  }
}

.payment-plan {
  &__list {
    @include respond-to(md) {
      display: flex;
      justify-content: space-between;
    }
  }

  &__item {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &:not(:last-child) {
      margin-bottom: 15px;
      padding-bottom: 15px;
      border-bottom: 1px solid rgba($black-color, 0.3);
    }

    span,
    h5,
    p {
      opacity: 0.5;
    }

    span {
      width: 40px;
    }

    h5 {
      margin-right: auto;
      margin-bottom: 3px;
      width: calc(100% - 40px - 100px);
    }

    p {
      padding-left: 40px;
      padding-right: 55px;
      order: 2;
      width: 100%;
      font-size: 1rem;
    }

    @include respond-to(md) {
      padding-top: 30px;
      padding-bottom: 30px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      text-align: center;
      width: 16.66%;
      padding-left: 15px;
      padding-right: 15px;

      &:not(:last-child) {
        margin-bottom: 0;
        padding-bottom: 0;
        border-bottom: none;
        border-right: 1px solid rgba($black-color, 0.3);
      }

      span {
        margin-bottom: 15px;
      }

      strong {
        margin-bottom: 50px;
      }

      h5 {
        margin-bottom: 50px;
        width: 100%;
      }

      p {
        padding-left: 0;
        padding-right: 0;
        opacity: 1;
        font-size: 1.4rem;
      }

      span {
        font-size: 1.6rem;
      }

      @media (max-width: 1279px) {
        h5 {
          font-size: 1.3rem;
        }
      }
    }

    @include respond-to(lg) {
      p,
      span {
        font-size: 1.8rem;
      }

      h5 {
        font-size: 1.8rem;
        min-height: 42px;
      }
    }

    @include respond-to(xxl) {
      p,
      span {
        font-size: 2.4rem;
      }
    }
  }
}

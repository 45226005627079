// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------

/**
 * Main content containers
 * 1. Make the container full-width with a maximum width
 * 2. Center it in the viewport
 * 3. Leave some space on the edges, especially valuable on small screens
 */
.container {
  // max-width: $max-width; /* 1 */
  margin-left: auto;
  margin-right: auto;
  padding-left: var(--container-padding);
  padding-right: var(--container-padding);
  width: 100%;
}

/**
 * Hide element while making it readable for screen readers
 * Shamelessly borrowed from HTML5Boilerplate:
 * https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L119-L133
 */
.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

html.disable-scrolling {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

/**
* Embed Responsively
* http://embedresponsively.com/
*/
.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

/* Scroll reveal */
.is-observer {
  .reveal-slide {
    opacity: 0;
    transform: translateY(40px);
    @include transition-reveal;
  }

  .reveal-slide-content {
    > * {
      opacity: 0;
      transform: translateY(40px);
      @include transition-reveal;

      &.is-visible {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

  .reveal-fade {
    opacity: 0;
    @include transition-reveal;
  }

  .is-visible {
    &.reveal-del-1,
    .reveal-del-1 {
      transition-delay: 0.4s;
    }
    &.reveal-del-2,
    .reveal-del-2 {
      transition-delay: 0.7s;
    }
    &.reveal-del-3,
    .reveal-del-3 {
      transition-delay: 1s;
    }
    &.reveal-del-4,
    .reveal-del-4 {
      transition-delay: 1.3s;
    }

    .reveal-slide,
    &.reveal-slide {
      opacity: 1;
      transform: translateY(0);
    }

    .reveal-fade,
    &.reveal-fade {
      opacity: 1;
    }
  }
}

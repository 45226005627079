// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------
.page-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  padding-top: 17px;
  padding-bottom: 17px;
  display: flex;
  justify-content: space-between;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 155px;
    background: linear-gradient(
      hsla(0, 0%, 0%, 1) 0%,
      hsla(0, 0%, 0%, 0.738) 19%,
      hsla(0, 0%, 0%, 0.541) 34%,
      hsla(0, 0%, 0%, 0.382) 47%,
      hsla(0, 0%, 0%, 0.278) 56.5%,
      hsla(0, 0%, 0%, 0.194) 65%,
      hsla(0, 0%, 0%, 0.126) 73%,
      hsla(0, 0%, 0%, 0.075) 80.2%,
      hsla(0, 0%, 0%, 0.042) 86.1%,
      hsla(0, 0%, 0%, 0.021) 91%,
      hsla(0, 0%, 0%, 0.008) 95.2%,
      hsla(0, 0%, 0%, 0.002) 98.2%,
      hsla(0, 0%, 0%, 0) 100%
    );
    pointer-events: none;
    @include transition;
    content: '';
  }

  &.is-scrolling {
    &::before {
      opacity: 0;
    }
  }

  @include respond-to(sm) {
    padding-top: 25px;
    padding-bottom: 25px;

    &::before {
      height: 20vh;
      max-height: 320px;
    }
  }

  @include respond-to(lg) {
    padding-top: 37px;
    padding-bottom: 37px;
  }

  &__inner {
    position: relative;
    z-index: 2;
    width: 100%;
    min-height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__hamburger {
    position: absolute;
    top: calc(50% + 4px);
    right: 0;
    z-index: 4;
    transform: translateY(-50%);
    font-size: 1.4rem;
    font-weight: 900;
    text-transform: uppercase;

    span {
      &:last-child {
        display: none;
      }
    }

    @include respond-to(sm) {
      display: none;
    }

    @include when-inside('.is-opened') {
      span {
        &:first-child {
          display: none;
        }

        &:last-child {
          display: block;
        }
      }
    }
  }

  &__logo {
    position: absolute;
    left: 0;
    z-index: 3;
    display: inline-flex;
    align-items: center;
    transition: opacity 0.3s ease-in-out, left 0.3s ease-in-out;

    img,
    svg {
      width: 40px;
      fill: $white-color;
      @include transition;
    }

    > span {
      position: absolute;
      top: calc(50% + 3px);
      left: calc(100% + 15px);
      font-size: 1.7rem;
      line-height: 1.1;
      font-weight: 900;
      color: $white-color;
      letter-spacing: -0.01em;
      text-transform: uppercase;
      transform: translateY(-50%);
      @include transition;

      span {
        color: $brand-color;
      }
    }

    @include hover {
      svg {
        fill: $brand-color;
      }
    }

    @include when-inside('.is-home') {
      left: calc(50% - 20px);

      > span {
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
      }
    }

    @include when-inside('.is-home.is-logo', '.is-opened') {
      left: 0;

      > span {
        opacity: 1;
        visibility: visible;
        transition-delay: 0.3s;
      }
    }

    @include respond-to(sm) {
      left: calc(50% - 20px - 75px);

      img,
      svg {
        width: 56px;
      }

      @include when-inside('.is-home') {
        padding-right: 0;

        > span {
          opacity: 1;
          visibility: visible;
          transform: translateX(calc(-15px - 28px - 50%)) translateY(49vh)
            scale(calc(8 / 1.7));
        }
      }
    }

    @include respond-to(lg) {
      left: calc(50% - 20px - 95px);

      > span {
        font-size: 2.2rem;
      }

      @include when-inside('.is-home') {
        > span {
          opacity: 1;
          visibility: visible;
          transform: translateX(calc(-15px - 28px - 50%)) translateY(48vh)
            scale(calc(10 / 2.2));
        }
      }
    }

    @include respond-to(xl) {
      @include when-inside('.is-home') {
        > span {
          transform: translateX(calc(-15px - 28px - 50%)) translateY(50vh)
            scale(calc(12 / 2.2));
        }
      }
    }

    @include respond-to(xxl) {
      @include when-inside('.is-home') {
        > span {
          transform: translateX(calc(-15px - 28px - 50%)) translateY(51vh)
            scale(calc(16.6 / 2.2));
        }
      }
    }
  }

  &__enroll {
    display: none;
    font-weight: 900;
    font-size: 2.4rem;
    color: $white-color;
    letter-spacing: -0.01em;
    text-transform: uppercase;
    @include transition;

    span {
      color: $brand-color;
    }

    @include respond-to(sm) {
      margin-left: auto;
      margin-bottom: -3px;
      display: block;
      font-size: 1.8rem;
    }

    @include respond-to(lg) {
      margin-bottom: -8px;
      font-size: 2.4rem;
    }

    @include hover {
      transform: translateY(-4px);
    }

    @include when-inside('.nav') {
      display: block;
      color: $black-color;
      opacity: 0;

      span {
        color: $white-color;
      }
    }

    @include when-inside('.is-opened') {
      opacity: 1;
      transition-delay: calc(0.4s + 4 * 100ms);
    }
  }

  &__menu {
    display: none;

    button {
      @include transition;
    }

    a,
    button {
      color: $white-color;
      font-size: 2.4rem;
      line-height: 1.1;
      font-weight: 900;
      letter-spacing: -0.01em;
      text-transform: uppercase;

      @include hover {
        color: $brand-color;
      }

      &.is-active {
        color: $brand-color;
      }
    }

    @include respond-to(sm) {
      display: flex;
      align-items: center;

      li {
        &:not(:last-child) {
          margin-right: 20px;
        }
      }

      a,
      button {
        font-size: 1.2rem;
        font-weight: 400;
      }
    }

    @include respond-to(md) {
      a,
      button {
        font-size: 1.6rem;
      }
    }

    @include respond-to(lg) {
      margin-bottom: -2px;

      li {
        &:not(:last-child) {
          margin-right: 40px;
        }
      }
    }

    @include when-inside('.nav') {
      padding-top: 60px;
      height: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: $black-color;

      li {
        opacity: 0;
        @include transition;

        &:not(:last-child) {
          margin-bottom: 15px;
        }
      }
    }

    @include when-inside('.is-opened') {
      li {
        opacity: 1;
        @include transition-sequence(0.4s, 100ms);
      }
    }
  }
}

// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

:root {
  --container-padding: 40px;

  @include respond-to(md) {
    --container-padding: 50px;
  }

  @include respond-to(xl) {
    --container-padding: 5%;
  }

  @include respond-to(xxl) {
    --container-padding: 15%;
  }

  @media (min-width: 2500px) {
    --container-padding: 20%;
  }
}

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: none;
  -webkit-font-smoothing: antialiased;
}

body {
  max-width: 100%;
  overflow-x: hidden;

  background-color: $black-color;
}

main {
  position: relative;
  z-index: 2;
  background-color: $black-color;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
  box-sizing: inherit;

  margin: 0;
  padding: 0;
}

* {
  &:focus {
    outline: none;

    @include when-inside('.is-tab') {
      outline: 2px solid $brand-color;
    }
  }
}

img {
  display: block;
  height: auto;
  /* Make sure images are scaled correctly. */
  max-width: 100%;
  /* Adhere to container width. */
}

/**
 * Basic styles for links
 */
a {
  text-decoration: none;
  @include transition;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  padding: 0;
}

ul {
  list-style-type: none;
}

button {
  font-family: inherit;
  color: inherit;
  border: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
}

.skip-to-content-link {
  position: absolute;
  top: 0;
  left: 0;
  font-size: 2rem;
  color: #fff;
  opacity: 0;

  &:focus {
    z-index: 1000;
    opacity: 1;
  }
}

.swiper-pagination-bullet {
  margin-left: 2px;
  margin-right: 2px;
  width: 5px;
  height: 5px;
  background-color: $white-color;

  @include respond-to(sm) {
    margin-left: 3px;
    margin-right: 3px;
    width: 10px;
    height: 10px;
  }
}

.legals {
  margin-bottom: 100px;
  overflow: hidden;

  @include respond-to(sm) {
    margin-bottom: 150px;
  }

  @include respond-to(md) {
    margin-bottom: 200px;
  }

  header {
    display: flex;
    justify-content: center;
  }

  &__title {
    margin-top: 160px;
    margin-bottom: 80px;
    font-size: 4.8rem;
    font-weight: 900;
    text-transform: uppercase;
    white-space: nowrap;
    color: $brand-color;
    -webkit-text-fill-color: $black-color;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: $brand-color;

    @include when-inside('.legals--terms') {
      transform: translateX(10%);
    }

    @include respond-to(sm) {
      margin-top: 200px;
      margin-bottom: 200px;
      font-size: 10.5vw;
    }

    @include respond-to(md) {
      margin-top: 300px;
      margin-bottom: 300px;
    }
  }

  &__ordered {
    list-style-type: none;
    counter-reset: ordered;

    > li {
      position: relative;
      padding-top: 25px;
      counter-increment: ordered;

      &:not(:last-child) {
        margin-bottom: 50px;
      }

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        font-size: 1.4rem;
        font-weight: 900;
        color: $brand-color;
        content: counter(ordered, decimal-leading-zero);
      }

      @include respond-to(sm) {
        padding-top: 40px;

        &:not(:last-child) {
          margin-bottom: 100px;
        }

        &::before {
          font-size: 1.8rem;
        }
      }

      @include respond-to(md) {
        &::before {
          font-size: 2.4rem;
        }
      }
    }
  }

  &__block {
    padding-top: 25px;
    border-top: 1px solid $brand-color;

    &:not(:last-child) {
      margin-bottom: 50px;
    }

    h2 {
      margin-bottom: 25px;
    }

    @include respond-to(sm) {
      padding-top: 30px;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      &:not(:last-child) {
        margin-bottom: 100px;
      }

      h2 {
        margin-bottom: 0;
        width: 45%;
        max-width: 300px;
        font-size: 2.4rem;
      }
    }
  }

  &__copy {
    p,
    h3,
    ul {
      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }

    h3 {
      color: $brand-color;
      font-weight: 400;
      text-transform: uppercase;
    }

    ul {
      li {
        list-style-position: inside;

        &::marker {
          content: '- ';
        }
      }

      &.legals__list-spacing {
        li {
          &:not(:last-child) {
            margin-bottom: 15px;
          }
        }
      }
    }

    @include respond-to(sm) {
      width: 50%;

      p,
      h3,
      ul {
        &:not(:last-child) {
          margin-bottom: 25px;
        }
      }

      ul {
        &.legals__list-spacing {
          li {
            &:not(:last-child) {
              margin-bottom: 25px;
            }
          }
        }
      }
    }
  }

  &__links {
    margin-top: 50px;
    list-style-type: none;
    counter-reset: links;

    li {
      display: flex;
      align-items: baseline;
      vertical-align: baseline;
      counter-increment: links;

      &:not(:last-child) {
        margin-bottom: 15px;
      }

      &::before {
        margin-right: 5px;
        color: $brand-color;
        font-weight: 900;
        content: counter(links, decimal-leading-zero) '.';
      }
    }

    a {
      color: inherit;
      font-weight: 900;
      text-transform: uppercase;

      @include hover {
        img {
          transform: translateX(5px);
        }
      }
    }

    img {
      margin-left: 5px;
      display: inline-block;
      width: 11px;
      @include transition;

      @include respond-to(sm) {
        margin-left: 10px;
        width: 19px;
      }
    }
  }
}

.card {
  span {
    margin-bottom: 15px;
    display: inline-block;
    font-weight: 900;
    color: $brand-color;
    text-transform: uppercase;
  }

  h3 {
    margin-bottom: 15px;
  }

  @include respond-to(sm) {
    span {
      margin-bottom: 30px;
    }

    h3 {
      margin-bottom: 30px;
    }
  }

  &__media {
    position: relative;
    margin-bottom: 30px;

    &::before {
      padding-top: 64%;
      display: block;
      content: '';
    }

    img,
    video {
      @include objectFit;
    }

    @include respond-to(md) {
      margin-bottom: 50px;
    }
  }
}

.gallery {
  margin-bottom: 70px;

  @include respond-to(md) {
    margin-bottom: 200px;
  }

  &__inner {
    &::before,
    &::after {
      position: absolute;
      top: 0;
      bottom: 0;
      z-index: 3;
      width: 35px;
      content: '';
    }

    &::before {
      left: 0;
      background-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 0.87) 30.73%,
        rgba(0, 0, 0, 0) 100%
      );
    }

    &::after {
      right: 0;
      background-image: linear-gradient(
        to left,
        rgba(0, 0, 0, 0.87) 30.73%,
        rgba(0, 0, 0, 0) 100%
      );
    }

    @include respond-to(sm) {
      &::before,
      &::after {
        width: 15vw;
      }
    }
  }

  &__item {
    width: calc(100% - 2 * var(--container-padding));
    text-align: center;

    h3 {
      margin-bottom: 15px;
    }

    @include respond-to(sm) {
      width: 53%;
    }

    @include respond-to(md) {
      h3 {
        margin-bottom: 30px;
      }
    }
  }

  &__media {
    position: relative;
    margin-bottom: 30px;
    transform: scale(0.9);
    @include transition;

    &::before {
      padding-top: 56%;
      display: block;
      content: '';
    }

    img,
    video {
      @include objectFit;
    }

    @include when-inside('.swiper-slide-active') {
      transform: scale(1);
    }

    @include respond-to(md) {
      margin-bottom: 50px;
    }
  }

  &__copy {
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0;
    @include transition;

    p {
      max-width: 350px;
    }

    @include when-inside('.swiper-slide-active') {
      opacity: 1;
      @include transition-slow;
    }

    @include respond-to(sm) {
      p {
        max-width: 700px;
      }
    }
  }

  &__prev,
  &__next {
    position: absolute;
    z-index: 5;
    padding: 10px;
    display: none;
    @include transition;

    img {
      width: 22px;
    }

    @include respond-to(md) {
      display: block;
      top: calc(53vw / 1.8 * 0.5);
      transform: translateY(-50%);
    }
  }

  &__prev {
    left: 50px;

    @include hover {
      transform: translate(-5px, -50%);
    }
  }

  &__next {
    right: 50px;

    @include hover {
      transform: translate(5px, -50%);
    }
  }

  .swiper-container-horizontal > .swiper-pagination-bullets {
    top: calc((100vw - 2 * var(--container-padding)) / 1.8 - 10px);
    bottom: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(-100%);

    @include respond-to(sm) {
      top: calc(53vw / 1.8 - 20px);
    }

    @include respond-to(md) {
      top: calc(53vw / 1.8 - 30px);
    }
  }

  &__cta {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    .cta {
      white-space: nowrap;
    }

    > span {
      margin-left: 15px;
      margin-right: 15px;
    }

    img {
      padding-bottom: 10px;
      width: 100px;
    }

    @include respond-to(sm) {
      img {
        padding-bottom: 15px;
        width: 150px;
      }

      > span {
        margin-left: 25px;
        margin-right: 25px;
      }
    }
  }
}

.accordion {
  &__title {
    margin-bottom: 30px;

    @include respond-to(sm) {
      position: absolute;
      top: 0;
      left: 0;
      margin-bottom: 50px;
    }
  }

  &__inner {
    position: relative;
    margin-bottom: 50px;

    @include respond-to(sm) {
      margin-bottom: 100px;
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__media {
    position: relative;
    margin-bottom: 30px;

    @include respond-to(sm) {
      margin-bottom: 0;
      width: 41.66%;
    }

    &::before {
      padding-top: 130%;
      display: block;
      content: '';
    }

    img,
    video {
      @include objectFit;
    }
  }

  &__accordion {
    > li {
      border-bottom: 1px solid $white-color;

      &:first-child {
        border-top: 1px solid $white-color;
      }
    }

    @include respond-to(sm) {
      width: 50%;
    }
  }

  &__btn {
    padding-top: 15px;
    padding-bottom: 10px;
    width: 100%;
    text-align: left;
    @include transition;

    @include when-inside('.is-opened') {
      color: $brand-color;
    }

    @include hover {
      color: $brand-color;
    }
  }

  &__content {
    padding-bottom: 25px;
    display: none;

    h3 {
      margin-bottom: 10px;
    }

    .heading-lg {
      @include respond-to(xl) {
        font-size: 3rem;
      }
    }

    p {
      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }
  }
}

.split {
  position: relative;
  margin-bottom: 70px;
  overflow: hidden;

  @include respond-to(sm) {
    margin-bottom: 125px;
  }

  @include respond-to(md) {
    margin-bottom: 200px;
  }

  &__inner {
    display: flex;
    align-items: center;
    min-height: 100vh;

    > * {
      margin-bottom: 0;
    }
  }

  &__col {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 50vw;

    &--first {
      left: 0;
      transform: translateX(-100%);

      img {
        object-position: right center;
      }
    }

    &--second {
      right: 0;
      transform: translateX(100%);

      img {
        object-position: left center;
      }
    }

    @media (orientation: portrait) {
      left: 0;
      right: 0;
      width: 100vw;
      height: 50vh;

      &--first {
        bottom: auto;
        transform: translateY(-100%);
      }

      &--second {
        top: auto;
        transform: translateY(100%);
      }

      img {
        object-position: center center;
      }
    }

    img {
      @include objectFit;
    }

    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

.testimonials {
  @include respond-to(sm) {
    display: flex;
    align-items: center;
  }
  &__gallery {
    margin-bottom: 20px;

    @media (max-width: 767px) {
      .swiper-pagination {
        display: none;
      }
    }

    @include respond-to(sm) {
      margin-left: 0;
      width: 50%;
    }

    @include respond-to(lg) {
      .swiper-pagination {
        bottom: 30px;
      }
    }
  }

  &__media {
    position: relative;

    &::before {
      padding-top: 64.4%;
      display: block;
      content: '';
    }

    img,
    video {
      @include objectFit;
    }
  }

  &__inner {
    .swiper-pagination {
      position: static;
      margin-top: 20px;
      text-align: left;
    }

    @include respond-to(sm) {
      position: relative;
      width: 41%;
      max-width: 500px;

      .swiper-pagination {
        display: none;
      }
    }
  }

  &__controls {
    display: none;

    @include respond-to(sm) {
      position: absolute;
      top: -30px;
      right: 0;
      z-index: 2;
      display: block;
      background: #000;
    }
  }

  &__prev,
  &__next {
    padding: 10px;

    svg {
      width: 18px;
    }

    path {
      stroke: $white-color;
    }

    @include hover {
      path {
        stroke: $brand-color;
      }
    }
  }

  &__prev {
    margin-right: 10px;

    @include respond-to(lg) {
      margin-right: 20px;
    }
  }

  &__quotes {
    margin-bottom: 25px;

    @include respond-to(sm) {
    }

    @include respond-to(md) {
      margin-bottom: 50px;
    }
  }

  &__quote {
    cite {
      margin-bottom: 15px;
      display: inline-block;
      color: $brand-color;
      font-style: normal;
      text-transform: none;
    }

    p {
      font-weight: 900;
    }

    @include respond-to(md) {
      cite {
        margin-bottom: 30px;
      }
    }
  }
}

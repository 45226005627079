.media-text {
  margin-bottom: 50px;

  + .media-text {
    margin-top: 70px;
  }

  @include respond-to(sm) {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  @include respond-to(md) {
    margin-bottom: 200px;
  }

  &__carousel {
    margin-bottom: 25px;

    @include respond-to(sm) {
      margin-bottom: 50px;
      width: 100%;
    }

    @include respond-to(lg) {
      .swiper-pagination {
        bottom: 30px;
      }
    }
  }

  &__item {
    position: relative;

    &::before {
      padding-top: 56.25%;
      display: block;
      content: '';
    }

    img,
    video {
      @include objectFit;
    }
  }

  &__quote {
    margin-bottom: 15px;

    cite {
      margin-bottom: 15px;
      display: inline-block;
      font-style: normal;
      color: $brand-color;
    }

    @media (max-width: 767px) {
      p {
        font-size: 1.8rem;
      }
    }

    @include respond-to(sm) {
      margin-bottom: 0;
      width: 45%;
      max-width: 600px;

      cite {
        margin-bottom: 30px;
      }
    }
  }

  &__copy {
    p {
      max-width: 500px;

      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }

    @include respond-to(sm) {
      width: 50%;

      p {
        max-width: none;

        &:not(:last-child) {
          margin-bottom: 25px;
        }
      }
    }
  }
}

.features {
  margin-bottom: 70px;

  @include respond-to(md) {
    margin-bottom: 200px;
  }

  &__list {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;

    @include respond-to(lg) {
      max-width: 1200px;
    }

    @include respond-to(xxl) {
      max-width: 1325px;
    }
  }

  &__item {
    margin-top: 30px;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    &:first-child,
    &:nth-child(2) {
      margin-top: 0;
    }

    @include respond-to(sm) {
      flex-direction: row;
      text-align: left;

      span {
        margin-left: 30px;
      }
    }

    @include respond-to(md) {
      margin-top: 70px;
      width: calc(50% - 50px);

      span {
        margin-left: 40px;
      }
    }

    @include respond-to(xl) {
      margin-top: 130px;

      span {
        margin-left: 70px;
      }
    }

    @include respond-to(xxl) {
      width: calc(50% - 100px);
    }
  }

  &__icon {
    margin-bottom: 20px;
    width: 67px;
    height: 67px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #ffffff;
    border-radius: 50%;

    @include when-inside('.features__item:first-child') {
      img {
        width: 19px;
        height: 23px;
      }
    }

    @include when-inside('.features__item:nth-child(2)') {
      img {
        width: 24px;
        height: 24px;
      }
    }

    @include when-inside('.features__item:nth-child(3)') {
      img {
        width: 16px;
        height: 24px;
      }
    }

    @include when-inside('.features__item:nth-child(4)') {
      img {
        width: 24px;
        height: 24px;
      }
    }

    @include respond-to(sm) {
      margin-bottom: 0;
      width: 100px;
      height: 100px;

      @include when-inside('.features__item:first-child') {
        img {
          width: 27px;
          height: 33px;
        }
      }

      @include when-inside('.features__item:nth-child(2)') {
        img {
          width: 33px;
          height: 33px;
        }
      }

      @include when-inside('.features__item:nth-child(3)') {
        img {
          width: 22px;
          height: 33px;
        }
      }

      @include when-inside('.features__item:nth-child(4)') {
        img {
          width: 33px;
          height: 33px;
        }
      }
    }

    @include respond-to(lg) {
      width: 150px;
      height: 150px;

      @include when-inside('.features__item:first-child') {
        img {
          width: 42px;
          height: 51px;
        }
      }

      @include when-inside('.features__item:nth-child(2)') {
        img {
          width: 51px;
          height: 51px;
        }
      }

      @include when-inside('.features__item:nth-child(3)') {
        img {
          width: 33px;
          height: 51px;
        }
      }

      @include when-inside('.features__item:nth-child(4)') {
        img {
          width: 51px;
          height: 51px;
        }
      }
    }

    @include respond-to(xxl) {
      width: 200px;
      height: 200px;

      @include when-inside('.features__item:first-child') {
        img {
          width: 55px;
          height: 67px;
        }
      }

      @include when-inside('.features__item:nth-child(2)') {
        img {
          width: 67px;
          height: 67px;
        }
      }

      @include when-inside('.features__item:nth-child(3)') {
        img {
          width: 44px;
          height: 67px;
        }
      }

      @include when-inside('.features__item:nth-child(4)') {
        img {
          width: 67px;
          height: 67px;
        }
      }
    }
  }
}

/**
 * Basic typography style for copy text
 */

html {
  // This defines what 1 rem is
  font-size: 62.5%;
}

body {
  color: $text-color;
  font: normal 1.2rem / 1.1 $text-font-stack;
  letter-spacing: -0.01em;

  @include respond-to(sm) {
    font-size: 1.4rem;
  }

  @include respond-to(md) {
    font-size: 1.5rem;
  }

  @include respond-to(xl) {
    font-size: 1.8rem;
  }
}

p {
  font-family: $text-font-stack;
  line-height: 1.4;
}

.heading-xl,
.heading-lg,
.heading-md,
.heading-sm {
  font-family: $text-font-stack;
  text-transform: uppercase;
}

.heading-xl {
  font-size: 1.8rem;
  line-height: 1.2;
  font-weight: 900;

  @include respond-to(sm) {
    font-size: 2.4rem;
  }

  @include respond-to(md) {
    font-size: 3.6rem;
  }

  @include respond-to(xl) {
    font-size: 4.4rem;
  }
}

.heading-lg {
  font-size: 1.4rem;
  line-height: 1.2;
  font-weight: 900;

  @include respond-to(sm) {
    font-size: 2rem;
  }

  @include respond-to(md) {
    font-size: 2.4rem;
  }

  @include respond-to(lg) {
    font-size: 3rem;
  }

  @include respond-to(xl) {
    font-size: 3.6rem;
  }
}

.heading-md {
  font-size: 1.2rem;
  line-height: 1.2;
  text-transform: uppercase;
  font-weight: 900;

  @include respond-to(sm) {
    font-size: 1.8rem;
  }

  @include respond-to(lg) {
    font-size: 2rem;
  }

  @include respond-to(xl) {
    font-size: 2.4rem;
  }
}

.heading-sm {
  font-size: 1.2rem;
  line-height: 1.4;
  font-weight: 400;

  @include respond-to(sm) {
    font-size: 1.8rem;
  }

  @include respond-to(lg) {
    font-size: 2rem;
  }

  @include respond-to(xl) {
    font-size: 2.4rem;
  }
}

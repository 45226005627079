.popup {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  padding-top: 55px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 25px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba($black-color, 0.85);
  opacity: 0;
  visibility: hidden;
  @include transition-slow;

  &.is-opened {
    opacity: 1;
    visibility: visible;
  }

  @include respond-to(sm) {
    padding-bottom: 55px;
  }

  @media (min-height: 850px) {
    padding-top: 90px;
    padding-bottom: 90px;
  }

  &__copy {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    opacity: 0;
    @include transition;

    @include when-inside('.is-opened') {
      opacity: 1;
      transition-delay: 0.4s;
    }

    img {
      &:first-child {
        margin-bottom: 10vh;
        width: 48px;
      }

      &:last-child {
        margin-top: 10vh;
        width: 109px;
      }
    }

    @include respond-to(sm) {
      img {
        &:first-child {
          margin-bottom: 55px;
          width: 62px;
        }

        &:last-child {
          margin-top: 40px;
          width: 142px;
        }
      }
    }
  }

  &__inner {
    position: relative;
    z-index: 3;
    margin-left: auto;
    margin-right: auto;
    max-width: 1000px;
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    @include transition-slow;

    @include when-inside('.is-opened') {
      opacity: 1;
      visibility: visible;
      transition-delay: 3s;
    }

    iframe {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  &__close {
    position: absolute;
    top: -30px;
    right: 0;
    font-size: 1.4rem;
    font-weight: 900;
    text-transform: uppercase;
    color: $brand-color;

    @include respond-to(sm) {
      font-size: 1.8rem;
    }
  }
}

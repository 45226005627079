.cards {
  margin-bottom: 70px;

  .card {
    &:not(:last-child) {
      margin-bottom: 50px;
    }
  }

  @include respond-to(sm) {
    margin-bottom: 125px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 40px;
    row-gap: 100px;

    .card {
      &:not(:last-child) {
        margin-bottom: 0;
      }
    }
  }

  @include respond-to(md) {
    margin-bottom: 200px;
  }
}

.marquee {
  margin-top: 100px;
  margin-bottom: 100px;
  overflow: hidden;

  @include respond-to(md) {
    margin-top: 200px;
    margin-bottom: 200px;
  }

  @include respond-to(xl) {
    margin-top: 300px;
    margin-bottom: 300px;
  }

  &__line {
    padding-left: 15%;
    font-size: 4.8rem;
    font-weight: 900;
    text-transform: uppercase;
    white-space: nowrap;
    color: $brand-color;
    -webkit-text-fill-color: $black-color;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: $brand-color;

    @include respond-to(sm) {
      font-size: 10.5vw;
    }

    span {
      &:not(:last-child) {
        &::after {
          content: ' / ';
        }
      }
    }
  }
}

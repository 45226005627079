.schedule {
  &__list {
    @include respond-to(sm) {
      display: flex;
      justify-content: space-between;
    }
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 30px;
    }

    li {
      &:not(:last-child) {
        margin-bottom: 5px;
      }
    }

    h5 {
      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }

    @include respond-to(sm) {
      position: relative;
      padding: 40px 20px;
      width: 33.33%;

      &:not(:last-child) {
        margin-bottom: 0;
        border-right: 1px solid rgba($black-color, 0.1);
      }

      h5 {
        min-height: 42px;
      }

      li {
        font-size: 1.1rem;
      }
    }

    @include respond-to(md) {
      li {
        font-size: 1.4rem;

        &:not(:last-child) {
          margin-bottom: 15px;
        }
      }
    }

    @include respond-to(lg) {
      h5 {
        min-height: 71px;

        &:not(:last-child) {
          margin-bottom: 0;
        }
      }

      li {
        font-size: 1.8rem;
      }
    }

    @include respond-to(xl) {
      padding-left: 4%;
      padding-right: 4%;
    }
  }
}

.slider {
  margin-bottom: 70px;

  @include respond-to(sm) {
    margin-top: 200px;
    margin-bottom: 200px;
  }

  @include respond-to(md) {
    margin-top: 300px;
    margin-bottom: 300px;
  }

  &__inner {
    &::before,
    &::after {
      position: absolute;
      top: 0;
      bottom: 0;
      z-index: 3;
      width: 35px;
      content: '';
    }

    &::before {
      left: 0;
      background-image: linear-gradient(
        to right,
        rgba(0, 0, 0, 0.87) 30.73%,
        rgba(0, 0, 0, 0) 100%
      );
    }

    &::after {
      right: 0;
      background-image: linear-gradient(
        to left,
        rgba(0, 0, 0, 0.87) 30.73%,
        rgba(0, 0, 0, 0) 100%
      );
    }

    @include respond-to(sm) {
      &::before,
      &::after {
        width: 15vw;
      }
    }

    @include respond-to(md) {
      padding-left: 26vw;
      padding-right: 26vw;
    }
  }

  &__item {
    position: relative;
    text-align: center;
    width: calc(100vw - 2 * var(--container-padding));

    @include respond-to(sm) {
      width: 53%;
    }

    @include respond-to(md) {
      width: 32vw;
    }
  }

  &__media {
    position: relative;
    transform: scale(0.9);
    @include transition;

    &::before {
      padding-top: 64%;
      display: block;
      content: '';
    }

    img,
    video {
      @include objectFit;
    }

    @include respond-to(md) {
      transform: scale(0.85);
    }

    @include when-inside(
      '.swiper-slide-active',
      '.swiper-slide-duplicate-active'
    ) {
      transform: scale(1);
    }
  }

  &__prev,
  &__next {
    position: absolute;
    top: 50%;
    z-index: 5;
    padding: 10px;
    display: none;
    transform: translateY(-50%);
    @include transition;

    img {
      width: 22px;
    }

    @include respond-to(md) {
      display: block;
      transform: translateY(-50%);
    }
  }

  &__prev {
    left: 50px;

    @include hover {
      transform: translate(-5px, -50%);
    }
  }

  &__next {
    right: 50px;

    @include hover {
      transform: translate(5px, -50%);
    }
  }
}

// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------

/// Regular font family
/// @type List
$text-font-stack: 'Helvetica', 'Arial', sans-serif !default;

$white-color: #fff !default;
$text-color: $white-color;
$black-color: #000 !default;
$brand-color: #fecf3b !default;
$ldnm-color: #ec0d0d !default;

// /// Container's maximum width
// /// @type Length
// $max-width: 1170px !default;

/// Breakpoints map
/// @prop {String} keys - Keys are identifiers mapped to a given length
/// @prop {Map} values - Values are actual breakpoints expressed in pixels
$breakpoints: (
  'sm': only screen and
    (
      min-width: 768px,
    ),
  'md': only screen and
    (
      min-width: 1024px,
    ),
  'lg': only screen and
    (
      min-width: 1280px,
    ),
  'xl': only screen and
    (
      min-width: 1440px,
    ),
  'xxl': only screen and
    (
      min-width: 1920px,
    ),
) !default;

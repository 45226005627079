.nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  @include transition-slow;
  opacity: 0;
  visibility: hidden;

  @include when-inside('.is-opened') {
    opacity: 1;
    visibility: visible;
  }

  &__footer {
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $black-color;
    background-color: $brand-color;
  }

  &__sub-menu {
    margin-top: 6vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0;
    @include transition;

    @include when-inside('.is-opened') {
      opacity: 1;
      transition-delay: calc(0.4s + 5 * 100ms);
    }

    li {
      &:not(:last-child) {
        margin-bottom: 14px;
      }
    }

    a {
      color: inherit;
      font-size: 1.4rem;
      text-transform: uppercase;
    }
  }

  &__social {
    margin-top: 6vh;
    display: flex;
    opacity: 0;
    @include transition;

    @include when-inside('.is-opened') {
      opacity: 1;
      transition-delay: calc(0.4s + 6 * 100ms);
    }

    li {
      &:not(:last-child) {
        margin-right: 22px;
      }
    }
  }
}

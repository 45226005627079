.dates {
  padding-bottom: 100px;

  &__row {
    border-bottom: 1px solid $white-color;

    &:first-child {
      border-top: 1px solid $white-color;
    }

    &.is-opened {
      border-bottom-color: transparent;
    }
  }

  &__content {
    padding-top: 60px;
    padding-bottom: 35px;
    display: none;
    color: $black-color;
    background-color: $brand-color;

    @include respond-to(sm) {
      padding-top: 100px;
      padding-bottom: 70px;
    }
  }

  &__info {
    position: relative;

    &::after {
      position: absolute;
      top: calc(100% + 40px);
      left: 50%;
      width: 100vw;
      height: 1px;
      background-color: $black-color;
      transform: translateX(-50%);
      content: '';
      opacity: 0;
      pointer-events: none;
      @include transition;
    }

    @include when-inside('.is-plan', '.is-schedule') {
      &::after {
        opacity: 1;
      }
    }

    @include respond-to(sm) {
      display: flex;
      flex-wrap: wrap;

      &::after {
        top: calc(100% + 75px);
      }
    }

    @include respond-to(md) {
      flex-wrap: nowrap;
    }
  }

  &__col {
    li {
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    strong {
      display: inline-block;

      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }

    p {
      width: 100%;

      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }

    a {
      color: inherit;
      @include underline;

      &::after {
        left: 0;
        width: 100%;
        height: 1px;
      }

      @include hover {
        &::after {
          left: auto;
          width: auto;
        }
      }
    }

    @include respond-to(sm) {
      display: inline-block;
      width: calc(50% - 50px);

      &:nth-child(5) {
        margin-left: auto;
        margin-right: auto;
        text-align: center;
      }
    }

    @include respond-to(md) {
      padding-top: 60px;
      padding-bottom: 60px;
      width: calc((100% - 100px - 250px - 50px) / 2);
      font-size: 1.4rem;

      &:first-child {
        max-width: 230px;
      }

      &:nth-child(5) {
        margin-left: auto;
        margin-right: 25px;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: left;
      }

      li {
        &:not(:last-child) {
          margin-bottom: 15px;
        }
      }

      strong {
        min-height: 71px;
        font-size: 1.2rem;

        &:not(:last-child) {
          margin-bottom: 0;
        }
      }

      p {
        font-size: 1.2rem;
      }
    }

    @include respond-to(lg) {
      width: calc((100% - 100px - 350px - 50px) / 2);
      font-size: 1.8rem;

      &:first-child {
        max-width: 280px;
      }

      strong {
        min-height: 71px;
        font-size: 1.4rem;

        &:not(:last-child) {
          margin-bottom: 0;
        }
      }

      p {
        font-size: 1.8rem;
      }
    }

    @include respond-to(xl) {
      width: calc((100% - 200px - 350px - 50px) / 2);

      strong {
        font-size: 1.5rem;
      }
    }

    @include respond-to(xxl) {
      font-size: 2.4rem;

      &:first-child {
        max-width: 360px;
      }
    }
  }

  &__subtitle {
    margin-bottom: 30px;
    color: rgba($black-color, 0.5);

    @include when-inside('.dates__col:nth-child(5)') {
      color: $black-color;
    }

    @include respond-to(md) {
      margin-bottom: 50px;
    }

    @media (max-width: 767px) {
      font-size: 1.4rem;
    }

    @media (max-width: 1023px) {
      @include when-inside('.dates__total') {
        margin-bottom: 0;
      }
    }
  }

  &__divider {
    position: relative;
    margin-top: 25px;
    margin-bottom: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &::before,
    &::after {
      width: calc(50% - 30px);
      height: 1px;
      background-color: rgba($black-color, 0.1);
      content: '';
    }

    img {
      width: 27px;
    }

    @include respond-to(sm) {
      width: 100px;
      justify-content: center;

      &::before,
      &::after {
        display: none;
      }

      &:nth-child(4) {
        margin-bottom: 30px;
        width: 100%;
      }
    }

    @include respond-to(md) {
      margin-left: auto;
      margin-right: auto;
      width: 100px;

      &:nth-child(4) {
        width: 100px;
      }
    }
  }

  &__total {
    position: relative;
    margin-top: 30px;
    padding-top: 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    border-top: 1px solid rgba($black-color, 0.1);

    h4 {
      position: absolute;
      top: 30px;
      left: 0;
    }

    @include respond-to(sm) {
      margin-top: 50px;
      padding-top: 50px;

      h4 {
        top: 50px;
      }
    }

    @include respond-to(md) {
      margin-left: auto;
      margin-top: 0;
      padding-top: 60px;
      padding-bottom: 60px;
      width: 250px;
      justify-content: center;
      align-items: center;
      border-top: none;
      border-left: 1px solid rgba($black-color, 0.1);

      h4 {
        position: static;
      }
    }

    @include respond-to(lg) {
      width: 350px;
    }
  }

  &__toggle,
  &__schedule-toggle {
    margin-top: 10px;
    font-weight: 900;
    text-decoration: underline;
    text-transform: uppercase;

    @include hover {
      text-decoration: none;
    }

    @include respond-to(md) {
      margin-top: 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &::before {
      content: 'View ';
    }
  }

  &__toggle {
    @include when-inside('.is-plan') {
      &::before {
        content: 'Close ';
      }
    }
  }

  &__schedule-toggle {
    @include when-inside('.is-schedule') {
      &::before {
        content: 'Close ';
      }
    }
  }

  &__plan,
  &__schedule {
    position: relative;
    padding-top: 80px;
    display: none;

    @include respond-to(sm) {
      padding-top: 150px;
    }
  }

  &__enroll-wrap {
    margin-top: 50px;
    display: flex;
    justify-content: center;

    @include respond-to(sm) {
      margin-top: 100px;
    }
  }

  &__enroll {
    position: relative;
    color: $black-color;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: $black-color;

    &::before {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 5px;
      background-color: currentColor;
      content: '';
    }

    span {
      position: absolute;
      top: 0;
      right: 0;
      color: $black-color;
      -webkit-text-fill-color: $brand-color;
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: $black-color;
      clip-path: circle(0px at 100% 30%);
      @include transition-slow;
    }

    @include hover {
      span {
        clip-path: circle(200% at 100% 30%);
      }
    }

    @media (hover: hover) {
      &::before {
        display: none;
      }
    }
  }

  &__note {
    margin-top: 100px;
    font-size: 1rem;

    a {
      color: $brand-color;

      @include hover {
        color: $white-color;
      }
    }

    @include respond-to(sm) {
      font-size: 1.2rem;
    }

    @include respond-to(md) {
      margin-top: 200px;
      margin-bottom: 200px;
      font-size: 1.6rem;
    }
  }
}
